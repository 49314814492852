/* global gettext, pgettext */
/* eslint-disable no-tabs */
// shim for toISOString in IE8
if (!Date.prototype.toISOString) {
    (function() {
        function pad(number) {
            let r = String(number)
            if (r.length === 1) {
                r = '0' + r
            }
            return r
        }
        Date.prototype.toISOString = function() {
            return this.getUTCFullYear()
                + '-' + pad(this.getUTCMonth() + 1)
                + '-' + pad(this.getUTCDate())
                + 'T' + pad(this.getUTCHours())
                + ':' + pad(this.getUTCMinutes())
                + ':' + pad(this.getUTCSeconds())
                + '.' + String((this.getUTCMilliseconds() / 1000).toFixed(3)).slice(2, 5)
                + 'Z'
        }
    }())
}

// a	'a.m.' or 'p.m.' (Note that this is slightly different than PHP's output, because this includes periods to
//      match Associated Press style.)	'a.m.'
// A	'AM' or 'PM'.	'AM'
// b	Month, textual, 3 letters, lowercase.	'jan'
// B	Not implemented.
// c	ISO 8601 format. (Note: unlike others formatters, such as "Z", "O" or "r", the "c" formatter will not add
//      timezone offset if value is a naive datetime (see datetime.tzinfo).	2008-01-02T10:30:00.000123+02:00,
//      or 2008-01-02T10:30:00.000123 if the datetime is naive
// d	Day of the month, 2 digits with leading zeros.	'01' to '31'
// D	Day of the week, textual, 3 letters.	'Fri'
// e	Timezone name. Could be in any format, or might return an empty string, depending on the datetime.	'', 'GMT',
//      '-500', 'US/Eastern', etc.
// E	Month, locale specific alternative representation usually used for long date representation.	'listopada'
//      (for Polish locale, as opposed to 'Listopad')
// f	Time, in 12-hour hours and minutes, with minutes left off if they're zero. Proprietary extension.	'1', '1:30'
// F	Month, textual, long.	'January'
// g	Hour, 12-hour format without leading zeros.	'1' to '12'
// G	Hour, 24-hour format without leading zeros.	'0' to '23'
// h	Hour, 12-hour format.	'01' to '12'
// H	Hour, 24-hour format.	'00' to '23'
// i	Minutes.	'00' to '59'
// I	Daylight Savings Time, whether it's in effect or not.	'1' or '0'
// j	Day of the month without leading zeros.	'1' to '31'
// l	Day of the week, textual, long.	'Friday'
// L	Boolean for whether it's a leap year.	True or False
// m	Month, 2 digits with leading zeros.	'01' to '12'
// M	Month, textual, 3 letters.	'Jan'
// n	Month without leading zeros.	'1' to '12'
// N	Month abbreviation in Associated Press style. Proprietary extension.	'Jan.', 'Feb.', 'March', 'May'
// o	ISO-8601 week-numbering year, corresponding to the ISO-8601 week number (W)	'1999'
// O	Difference to Greenwich time in hours.	'+0200'
// P	Time, in 12-hour hours, minutes and 'a.m.'/'p.m.', with minutes left off if they're zero and the special-case
//      strings 'midnight' and 'noon' if appropriate. Proprietary extension.	'1 a.m.', '1:30 p.m.', 'midnight',
//      'noon', '12:30 p.m.'
// r	RFC 2822 formatted date.	'Thu, 21 Dec 2000 16:01:07 +0200'
// s	Seconds, 2 digits with leading zeros.	'00' to '59'
// S	English ordinal suffix for day of the month, 2 characters.	'st', 'nd', 'rd' or 'th'
// t	Number of days in the given month.	28 to 31
// T	Time zone of this machine.	'EST', 'MDT'
// u	Microseconds.	000000 to 999999
// U	Seconds since the Unix Epoch (January 1 1970 00:00:00 UTC).
// w	Day of the week, digits without leading zeros.	'0' (Sunday) to '6' (Saturday)
// W	ISO-8601 week number of year, with weeks starting on Monday.	1, 53
// y	Year, 2 digits.	'99'
// Y	Year, 4 digits.	'1999'
// z	Day of the year.	0 to 365
// Z	Time zone offset in seconds. The offset for timezones west of UTC is always negative, and for those east of UTC
//      is always positive.	-43200 to 43200
Date.prototype.format = function(newFormat) {  // eslint-disable-line complexity

    const weekDay3 = [gettext('Mon'), gettext('Tue'), gettext('Wed'), gettext('Thu'), gettext('Fri'), gettext('Sat'), gettext('Sun')]  // eslint-disable-line max-len
    const weekDay = [gettext('Monday'), gettext('Tuesday'), gettext('Wednesday'), gettext('Thursday'), gettext('Friday'), gettext('Saturday'), gettext('Sunday')]  // eslint-disable-line max-len
    const months3 = [pgettext('month3', 'jan'), pgettext('month3', 'feb'), pgettext('month3', 'mar'), pgettext('month3', 'apr'), pgettext('month3', 'may'), pgettext('month3', 'jun'), pgettext('month3', 'jul'), pgettext('month3', 'aug'), pgettext('month3', 'sep'), pgettext('month3', 'oct'), pgettext('month3', 'nov'), pgettext('month3', 'dec')]  // eslint-disable-line max-len
    const monthShort = [pgettext('abbrev. month', 'Jan.'), pgettext('abbrev. month', 'Feb.'), pgettext('abbrev. month', 'March'), pgettext('abbrev. month', 'April'), pgettext('abbrev. month', 'May'), pgettext('abbrev. month', 'June'), pgettext('abbrev. month', 'July'), pgettext('abbrev. month', 'Aug.'), pgettext('abbrev. month', 'Sept.'), pgettext('abbrev. month', 'Oct.'), pgettext('abbrev. month', 'Nov.'), pgettext('abbrev. month', 'Dec.')]  // eslint-disable-line max-len
    const altMonthName = [pgettext('alt. month', 'January'), pgettext('alt. month', 'February'), pgettext('alt. month', 'March'), pgettext('alt. month', 'April'), pgettext('alt. month', 'May'), pgettext('alt. month', 'June'), pgettext('alt. month', 'July'), pgettext('alt. month', 'August'), pgettext('alt. month', 'September'), pgettext('alt. month', 'October'), pgettext('alt. month', 'November'), pgettext('alt. month', 'December')]  // eslint-disable-line max-len
    const MonthName = [gettext('January'), gettext('February'), gettext('March'), gettext('April'), gettext('May'), gettext('June'), gettext('July'), gettext('August'), gettext('September'), gettext('October'), gettext('November'), gettext('December')]  // eslint-disable-line max-len

    const pD = {
        a : (this.getHours() > 12) ? 'p.m.' : 'a.m.',
        A : (this.getHours() > 12) ? 'PM' : 'AM',
        b : months3[this.getMonth()],
        B : 'B',
        c : this.toISOString(),
        d : ((this.getDate().toString()).length === 1) ? '0' + this.getDate() : this.getDate(),
        D : weekDay3[this.getDay()],
        e : this.getTimezoneOffset(),       // Returns the time difference between UTC time and local time, in minutes.
        E : altMonthName[this.getMonth()],
        f : (this.getHours() >= 12) ? ((this.getMinutes() === 0) ? this.getHours() - 12 : this.getHours() - 12 + ':' + this.getMinutes()) : ((this.getMinutes() === 0) ? this.getHours() : this.getHours() + ':' + this.getMinutes()),  // eslint-disable-line max-len
        F : MonthName[this.getMonth()],
        g : (this.getHours() >= 12) ? this.getHours() - 12 : this.getHours(),
        G : this.getHours(),
        h : (this.getHours() >= 12) ? ((((this.getHours() - 12).toString()).length === 1) ? '0' + this.getHours() - 12 : this.getHours() - 12) : ((this.getHours().toString()).length === 1 ? '0' + this.getHours() : this.getHours()),  // eslint-disable-line max-len
        H : ((this.getHours().toString()).length === 1) ? '0' + this.getHours() : this.getHours(),
        i : ((this.getMinutes().toString()).length === 1) ? '0' + this.getMinutes() : this.getMinutes(),
        I : 'I',
        j : this.getDate(),
        l : weekDay[this.getDay()],
        L : (this.getFullYear() % 4 !== 0) ? false : ((this.getFullYear() % 100 === 0 && this.getFullYear() % 400 === 0) ? true : false),  // eslint-disable-line max-len
        m : (((this.getMonth() + 1).toString()).length === 1) ? '0' + (this.getMonth() + 1) : this.getMonth() + 1,
        M : (months3[this.getMonth()]).charAt(0).toUpperCase() + (months3[this.getMonth()]).slice(1),
        n : this.getMonth() + 1,
        N : monthShort[this.getMonth()],
        o : 'o',
        O : (this.toTimeString()).slice(12, 17),
        P : 'P',
        r : this.toString(),
        s : ((this.getSeconds().toString()).length === 1) ? '0' + this.getSeconds() : this.getSeconds(),
        S : 'S',
        t : 't',
        T : 'T',
        u : (this.getTime().toString()).substring(0, (this.getTime().toString()).length - 3),
        U : this.getTime(),
        w : this.getDay(),
        W : 'W',
        y : this.getFullYear(),
        Y : this.getFullYear(),
        z : 'z',
        Z : this.getTimezoneOffset() * 60,
    }

    const separatedString = newFormat.split('')
    let result = ''
    for (let i = 0;i < separatedString.length;i++) {
        if (pD[separatedString[i]]) {
            result += pD[separatedString[i]]
        } else {
            result += separatedString[i]
        }
    }

    return result
}
